import React from 'react';
import { Grid, Hide, Rectangle } from '@pitchero/react-ui';
import AppAdvert from '../app-advert';
import NoBackground from '../club-frame/header/no-background';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import LoginFrom from './login-form';

const LoginPage = ({ club }) => (
  <>
    <NoBackground />
    <Grid columns="400px 1fr" responsive={[{ maxWidth: 'tab', props: { columns: '1fr' } }]}>
      <Rectangle fill="white" component="div">
        <LoginFrom />
      </Rectangle>
      <Hide maxWidth="tab">
        <AppAdvert clubName={club.name} />
      </Hide>
    </Grid>
  </>
);

LoginPage.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
};

export default LoginPage;
