import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Head from 'next/head';
import Router from 'next/router';
import LoginPageComponent from '../../components/auth/login-page';
import isServer from '../../lib/is-server';
import clubUrl from '../../lib/routing/club-url';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import { isLoggedIn as isLoggedInSelector } from '../../store/auth/selectors';

const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

const LoginPage = ({ club, isLoggedIn, redirectUrl }) => {
  if (!isServer() && isLoggedIn) {
    Router.push(redirectUrl);
  }

  return (
    <>
      <Head>
        <title>{`Login to ${club.name}`}</title>
      </Head>
      <LoginPageComponent club={club} />
    </>
  );
};

LoginPage.getInitialProps = async ({ club, query, reduxStore, res }) => {
  const { returnUrl } = query;
  const redirectUrl = returnUrl && validURL(returnUrl) ? returnUrl : clubUrl(club, 'homepage');
  const isLoggedIn = isLoggedInSelector(reduxStore.getState());
  if (isLoggedIn && res) {
    res.writeHead(302, {
      Location: redirectUrl,
    });
    res.end();
  }

  return {
    redirectUrl,
    namespacesRequired: ['common'],
    pageType: 'auth.login',
  };
};

LoginPage.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedInSelector(state),
});

export default connect(mapStateToProps)(LoginPage);
